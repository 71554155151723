import React, { useState, useEffect } from 'react';
import styles from './IrmaDisclose.module.css';
import yivi from '@privacybydesign/yivi-frontend';
import { useNavigate } from 'react-router-dom';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import IrmaQR from '../generic/IrmaQR';
import PoweredBy from '../generic/PoweredBy';

export default function IrmaDisclose() {
  let navigate = useNavigate();
  const [serverError, setServerError] = useState();

  useEffect(() => {
    let options = {
      debugging: false,
      element: '#irma-web-form',
      state: {
        polling: {
          endpoint: 'status',
          interval: 1500,
          startState: 'INITIALIZED',
        },
      },
      session: {
        start: {
          url: (o) =>
            `${o.url}/api/session/disclose?redirect=${encodeURI(
              window.location.href
            )}`,
        },
        mapping: {
          sessionPtr: (r) => r.sessionPtr,
          sessionToken: (r) => r.sessionToken,
          frontendRequest: (r) => r.frontendRequest,
        },
        result: false,
      },
    };

    const yiviInstance = yivi.newWeb(options);
    yiviInstance
      .start()
      .then((response) => {
        if (response.error) {
          setServerError(response.error);
          return;
        }
       
        navigate({
          search: `${window.location.search}&token=${response.sessionToken}`,
          replace: true,
        });
      })
      .catch((error) => {
        // irma frontend bug: REST errors are not catched here
        console.log(error.response);
      });
  }, []);

  return (
    <div className={styles.main}>
      <div>
        <h1 className={styles.title}>Deel je persoonsgegevens</h1>
        {serverError === 'invalid-data' ? (
          <div style={{ fontSize: '0.9em', color: '#cc2020' }}>
            We hebben niet de juiste persoonsgegevens van je ontvangen. We
            hebben voornamen, achternaam en geboortedatum van je nodig.
          </div>
        ) : (
          <IrmaQR />
        )}
      </div>

      <ButtonContainer>
        {serverError === 'invalid-data' && (
          <Button color="white" onClick={() => window.location.reload()}>
            Probeer opnieuw
          </Button>
        )}
      </ButtonContainer>
      <PoweredBy />
    </div>
  );
}
