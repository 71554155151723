import React, { useEffect } from 'react';
import Steps from '../generic/Steps';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import EvaluatorEmail from './EvaluatorEmail';

export default function Share({
  authorizationID,
  setOneAuthorization,
  evaluatorEmail,
  shareData,
  setShowSharing,
  setToast,
}) {
  useEffect(() => {
    async function share() {
      try {
        await navigator.share(shareData);
        setShowSharing(false);
      } catch (err) {
        console.log(err);
        setToast({
          message: 'Er ging iets mis met het delen.',
          color: '#cc2020',
        });
        setShowSharing(false);
      }
    }
    if (isMobile && navigator.share && navigator.canShare(shareData)) {
      share();
    }
  }, []);

  function onSubmit(data) {
    let formData = new FormData();
    formData.append('evaluatorEmail', data.evaluatorEmail);
    formData.append('authorizationID', authorizationID);

    axios
      .post('/api/sendauthorizationrequest', formData)
      .then(({ data }) => {
        setOneAuthorization(data);
        setShowSharing(false);
      })
      .catch((error) => {
        console.log(error.response);
        setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
      });
  }

  if (isMobile && navigator.share && navigator.canShare(shareData)) {
    return (
      <div>
        <Steps current={2} total={4} />
        <h1>Machtigingsaanvraag versturen</h1>
        <p>Stuur naar degene, bij wie je de machtiging wil aanvragen.</p>
      </div>
    );
  }
  return (
    <EvaluatorEmail
      evaluatorEmail={evaluatorEmail}
      onSubmit={onSubmit}
      back={() => setShowSharing(false)}
    />
  );
}
