import React from 'react';
import styles from './Home.module.css';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import { useForm } from 'react-hook-form';

export default function Home({ authorizations }) {
  let navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const { register, handleSubmit } = useForm({
    defaultValues: { registeredInHR: searchParams.get('inhr') || 'ja' },
  });

  function onSubmit(data) {
    if (!data.registeredInHR) return;
    if (data.registeredInHR === 'ja') {
      navigate({
        pathname: './bevoegdheiduittreksel',
        // search: window.location.search,
      });
    } else if (data.registeredInHR === 'nee') {
      navigate({
        pathname: './infoauthorization',
        // search: window.location.search,
      });
    }
  }

  function onChange(event) {
    searchParams.set('inhr', event.target.value);
    setSearchParams(searchParams, { replace: true });
  }

  let pendingAuthorizationRequests = authorizations.filter(
    (auth) => auth.status === 'AWAITING_APPROVAL'
  );
  return (
    <div className={styles.main}>
      <h1>Sta je ingeschreven bij de KVK?</h1>
      <p>
        Sta je <strong>persoonlijk</strong> ingeschreven in het{' '}
        <strong>KVK handelsregister</strong> als eigenaar of bestuurder van je
        organisatie?
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ padding: '12px 0 24px' }}>
          <label className={styles.radioButton} htmlFor="registeredInHR">
            <input
              {...register('registeredInHR', {
                onChange,
              })}
              type="radio"
              value="ja"
              id="registeredInHR"
            />
            Ja, ik sta persoonlijk ingeschreven
          </label>
          <label className={styles.radioButton} htmlFor="notRegisteredInHR">
            <input
              {...register('registeredInHR', {
                onChange,
              })}
              type="radio"
              value="nee"
              id="notRegisteredInHR"
            />
            Nee, ik wil een machtiging aanvragen
          </label>
        </div>
        <ButtonContainer>
          {location.key !== 'default' && (
            <Button
              color="white"
              onClick={() => {
                navigate(-1);
              }}
            >
              Terug
            </Button>
          )}
          <Button type="submit">Verder</Button>
        </ButtonContainer>
      </form>
      {!!pendingAuthorizationRequests.length && (
        <p>
          <a
            href="#id"
            onClick={(event) => {
              event.preventDefault();
              navigate({
                pathname: './view',
                search: window.location.search,
              });
            }}
          >
            Ga naar lopende machtigingsaanvragen
          </a>
        </p>
      )}
    </div>
  );
}
