import React from 'react';
import styles from './Button.module.css';

function Button({ children, color, small, type, ...props }) {
  let cn = styles.main;
  cn += ' ' + styles[color || 'blue'];
  if (props.disabled) {
    cn += ' ' + styles.disabled;
  }
  if (small){
    cn += ' ' + styles.small;
  }
  if (type === 'submit'){
    return (
      <button className={cn} {...props}>
        {children}
      </button>
    );
  }
  return (
    <a className={cn} {...props}>
      {children}
    </a>
  );
}

export default Button;
