import React from 'react';
import styles from './RelyingParties.module.css';
import { useNavigate } from 'react-router-dom';

function AuthType({ relyingParty }) {
  let navigate = useNavigate();
  // const [expanded, setExpanded] = useState(true);

  return (
    <div
      // className={styles.authTypeMain + ' ' + (expanded ? styles.expanded : '')}
      className={styles.authTypeMain}
    >
      <div
        className={styles.authTypeHeader}
        // onClick={() => setExpanded(!expanded)}
      >
        <div>
          <span>Bij</span> <strong>{relyingParty.naam}</strong>
          <span>:</span>
        </div>
        {/* <svg className={styles.arrow} focusable="false" viewBox="0 0 24 24">
          <path d="M3.68817 6.76378V6.76378C4.43894 6.01249 5.41494 5.71198 6.1657 6.53839L11.9466 12.3233L17.8026 6.46326C18.5533 5.71198 19.5293 5.93737 20.2801 6.68865V6.68865C21.0309 7.43993 21.3312 8.4166 20.5053 9.16788L13.298 16.3802C12.5472 17.1315 11.346 17.1315 10.5952 16.3802L3.46294 9.24301C2.71218 8.49173 2.93741 7.51506 3.68817 6.76378Z"></path>
        </svg> */}
      </div>
      {/* {expanded && ( */}
      <div className={styles.authTypeBody}>
        {relyingParty.authorizationTypes.map((authType) => (
          <a
            href="#1"
            className={styles.authTypeItem}
            key={authType.code}
            onClick={(event) => {
              event.preventDefault();
              navigate(
                `/getauthorization/requestauthorization?authorization=${authType.code}`
              );
            }}
          >
            <div>{authType.description}</div>
            {/* <div>⟩</div> */}
            <svg
              className={styles.arrow}
              fill="#000000"
              // height="800px"
              // width="800px"
              version="1.1"
              id="Layer_1"
              viewBox="0 0 330 330"
            >
              <path d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001  c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213  C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606  C255,161.018,253.42,157.202,250.606,154.389z" />
            </svg>
          </a>
        ))}
      </div>
      {/* )} */}
    </div>
  );
}

export default function RelyingParties({ relyingParties, title, subTitle }) {
  return (
    <div>
      {title && <h1>{title}</h1>}
      {subTitle && <p>{subTitle}</p>}
      {relyingParties.map((rp) => (
        <AuthType key={rp.kvkNummer} relyingParty={rp} />
      ))}
    </div>
  );
}
