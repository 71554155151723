import React, { useEffect, useState } from 'react';
import { Routes, Route, useSearchParams, Navigate } from 'react-router-dom';
import IrmaDiscloseOrganization from './IrmaDisclose';
import Evaluate from './Evaluate';
import Invite from './Invite';
import View from './View';
import Register from './Register';
import axios from 'axios';
import ButtonContainer from '../generic/ButtonContainer';
import Button from '../generic/Button';

function NotAuthorized({ notAuthorized, setNotAuthorized }) {
  let message;
  let reason = notAuthorized?.reason;

  if (reason === 'expired') {
    message = (
      <div>
        <h1>Je KVK kaartje is verlopen</h1>
        <p>
          Om mensen te kunnen machtigen voor je organisatie mag je KVK kaartje
          niet ouder zijn dan 6 maanden. Ga terug, scan opnieuw de QR code,
          wijzig je keuze in de app en kies voor een nieuw KVK
          Bevoegheidskaartje. Volg vervolgens de stappen om een nieuw KVK
          kaartje op te halen.
        </p>
      </div>
    );
  } else {
    message = (
      <div>
        <h1>Je bent niet bevoegd genoeg</h1>
        <p>
          Om mensen te kunnen machtigen voor je organisatie moet je in het KVK
          Handelsregister geregistreerd staan als onbeperkt bevoegd/gemachtigd.
        </p>
        <p>
          De reden van je beperkte bevoegdheid is: <strong>{reason}</strong>.
        </p>
        <p>
          Om dit op te lossen, kun je jezelf laten registreren bij de KVK als
          gevolmachtigde van je organisatie, met een volledige volmacht. Ga naar
          kvk.nl om je registratie aan te passen.
        </p>
      </div>
    );
  }

  return (
    <div>
      {message}
      <ButtonContainer>
        <Button color="white" onClick={() => setNotAuthorized(null)}>
          Terug
        </Button>
      </ButtonContainer>
    </div>
  );
}

function Protected({ setToast, session, authorizations, setOneAuthorization }) {
  if (authorizations?.loading) return <div>loading authorizations...</div>;
  return (
    <Routes>
      <Route
        path=""
        element={<View session={session} authorizations={authorizations} />}
      ></Route>
      <Route path="invite" element={<Invite session={session} />}></Route>
      <Route
        path="register"
        element={<Register session={session} authorizations={authorizations} />}
      ></Route>
      <Route
        path="evaluate/:authorizationID"
        element={
          <Evaluate
            session={session}
            authorizations={authorizations}
            setOneAuthorization={setOneAuthorization}
            setToast={setToast}
          />
        }
      ></Route>
      <Route path="*" element={<Navigate to="/authorizesomeone" replace />} />
    </Routes>
  );
}

export default function ProtectedContainer({
  setToast,
  session,
  setSession,
  authorizations,
  setOneAuthorization,
  fetchAuthorizations,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get('token');
  const [notAuthorized, setNotAuthorized] = useState();

  useEffect(() => {
    if (session?.identityKVK) {
      if (token) {
        searchParams.delete('token');
        setSearchParams(searchParams, { replace: true });
      }
      return;
    }
    if (token) {
      axios
        .get(`/api/kvk/session/${token}/result`)
        .then(({ data }) => {
          setSession(data);
          fetchAuthorizations();
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setNotAuthorized(error.response?.data); // expired or reason of restrictedAuthorization
          } else {
            console.log(error.message);
            setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
          }
        })
        .finally(() => {
          searchParams.delete('token');
          setSearchParams(searchParams, { replace: true });
        });
    }
  }, [token]);

  if (session?.identityKVK) {
    return (
      <Protected
        session={session}
        authorizations={authorizations}
        setOneAuthorization={setOneAuthorization}
        setToast={setToast}
      />
    );
  }
  if (notAuthorized) {
    return (
      <NotAuthorized
        notAuthorized={notAuthorized}
        setNotAuthorized={setNotAuthorized}
      />
    );
  }
  if (token) return <div>loading session...</div>;
  return <IrmaDiscloseOrganization />;
}
