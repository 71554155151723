import React, { useState } from 'react';
import styles from './Register.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ButtonContainer from '../generic/ButtonContainer';
import Button from '../generic/Button';

export default function Register({ session }) {
  let navigate = useNavigate();
  const location = useLocation();
  const [typeAuthorization, setTypeAuthorization] = useState();

  return (
    <div className={styles.main}>
      <h1>Machtiging registreren voor {session.identityKVK.naam}</h1>
      <p>
        Heb je een online service, die je beschikbaar wil stellen voor
        gemachtigde personen, dan kun je hier je machtiging definieren, die een
        gemachtigde moet hebben om toegang tot je service te verkrijgen.
      </p>
      <div className={styles.inputContainer}>
        <input
          className={styles.inputText}
          type="text"
          placeholder="bv. Vergunning aanvragen"
          onChange={(e) => {
            setTypeAuthorization(e.target.value);
          }}
        />
        <div>bij {session.identityKVK.naam}</div>
      </div>

      <p>
        Zodra je deze machtiging hebt geregistreerd, hebben
        eigenaren/bestuurders de mogelijkheid om hun werknemers deze machting
        toe te kennen.
      </p>
      <ButtonContainer>
        {location.key !== 'default' && (
          <Button
            color="white"
            onClick={() => {
              navigate(-1);
            }}
          >
            Terug
          </Button>
        )}
        <Button
          onClick={() => {
            console.log(typeAuthorization);
          }}
        >
          Registreer
        </Button>
      </ButtonContainer>
    </div>
  );
}
