import React, { useEffect } from 'react';
import styles from './IrmaDisclose.module.css';
import yivi from '@privacybydesign/yivi-frontend';
import { useNavigate } from 'react-router-dom';
import IrmaQR from '../generic/IrmaQR';
import PoweredBy from '../generic/PoweredBy';

export default function IrmaDisclose() {
  let navigate = useNavigate();

  useEffect(() => {
    let options = {
      debugging: false,
      element: '#irma-web-form',
      state: {
        polling: {
          endpoint: 'status',
          interval: 1500,
          startState: 'INITIALIZED',
        },
      },
      session: {
        start: {
          url: (o) =>
            `${o.url}/api/kvk/session/disclose?redirect=${encodeURI(
              window.location.href
            )}`,
        },
        mapping: {
          sessionPtr: (r) => r.sessionPtr,
          sessionToken: (r) => r.sessionToken,
          frontendRequest: (r) => r.frontendRequest,
        },
        result: false,
      },
    };

    const yiviInstance = yivi.newWeb(options);
    yiviInstance
      .start()
      .then((response) => {
        navigate({
          search: `${window.location.search}&token=${response.sessionToken}`,
          replace: true,
        });
      })
      .catch((error) => {
        // irma frontend bug: REST errors are not catched here
        console.log(error.response);
      });
  }, []);

  return (
    <div className={styles.main}>
      <div>
        <h1 className={styles.title}>Deel je KVK bevoegdheidsgegevens</h1>
        <p>
          We hebben je KVK bevoegdheidsgegevens nodig om te controleren of je
          bevoegd bent om personen te machtigen namens je organisatie.
        </p>

        <IrmaQR includePermissionsManager/>
      </div>
      <PoweredBy />
    </div>
  );
}
