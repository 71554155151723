import React from 'react';
import styles from './ButtonContainer.module.css';

export default function ButtonContainer({ children, ...props }) {
  return (
    <div className={styles.main} {...props}>
      {children}
    </div>
  );
}
