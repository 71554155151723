import React from 'react';
import styles from './EvaluatorEmail.module.css';
import Steps from '../generic/Steps';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

export default function EvaluatorEmail({ evaluatorEmail, onSubmit, back }) {
  const [searchParams] = useSearchParams();
  let authorizationCode = searchParams.get('authorization');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      evaluatorEmail,
    },
  });
  return (
    <div className={styles.main}>
      <Steps current={2} total={4} />
      <h1 className={styles.title}>Machtigingsaanvraag versturen</h1>

      <div>
        {/* <h1 className={styles.title}>Machtigingsaanvraag versturen</h1> */}
        <p>
          Vul het emailadres in van degene aan wie je de machtigingsaanvraag wil
          versturen.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <span style={{ fontWeight: '500' }}>
              {authorizationCode ===
              'signicat_machtigingenbeheerder'
                ? 'Emailadres van eigenaar of bestuurder'
                : 'Emailadres van eigenaar/bestuurder (of machtigingenbeheerder)'}
            </span>
          </div>
          <div className={styles.inputContainer}>
            <div>
              <input
                className={styles.inputText}
                type="email"
                {...register('evaluatorEmail', {
                  required: {
                    value: true,
                    message: 'Emailadres is vereist',
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                    message: 'Geen juist emailadres',
                  },
                })}
              />
            </div>

            <div className={styles.error}>{errors.evaluatorEmail?.message}</div>
          </div>

          <ButtonContainer>
            <Button color="white" onClick={back}>
              Terug
            </Button>
            <Button type="submit">Verstuur aanvraag</Button>
          </ButtonContainer>
        </form>
      </div>
    </div>
  );
}
