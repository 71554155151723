import React, { useState, useEffect, Suspense } from 'react';
import styles from './AwaitingAuthorization.module.css';
import Steps from '../generic/Steps';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import IrmaIssue from './IrmaIssue';
import axios from 'axios';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import Dialog from '../generic/Dialog';
import Share from './Share';

function Awaiting({
  authorization,
  setShowSharing,
  cancelAuthorizationRequest,
}) {
  return (
    <div className={styles.main}>
      <Steps current={3} total={4} />
      <h1 className={styles.title}>Wacht op goedkeuring...</h1>
      {/* <div className={styles.warning}>
        <div className={styles.infoIcon}>ⓘ</div>
        <div>
          We kunnen niet controleren hoe de aanvraag is gedeeld en of hij is
          aangekomen bij de ontvanger.
        </div>
      </div> */}

      <p style={{ fontSize: '2.6em', textAlign: 'center' }}>⌛</p>
      <p>Je aanvraag wacht op goedkeuring.</p>
      <p>
        Je ontvangt een email op <strong>{authorization.requestorEmail}</strong>
        , wanneer de aanvraag is goedgekeurd of afgewezen.
      </p>
      <p>Je kan wachten of dit browservenster sluiten.</p>
      <p>
        <a
          href="#id"
          onClick={(event) => {
            event.preventDefault();
            setShowSharing(true);
          }}
        >
          Aanvraag nogmaals versturen
        </a>
      </p>
      <p>
        <a
          href="#id"
          onClick={(event) => {
            event.preventDefault();
            cancelAuthorizationRequest(true);
          }}
        >
          Aanvraag afbreken
        </a>
      </p>
    </div>
  );
}

function AwaitingAuthorization({
  setToast,
  authorization,
  setOneAuthorization,
  cancelAuthorizationRequest,
}) {
  const [showSharing, setShowSharing] = useState();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('share')) {
      searchParams.delete('share');
      setSearchParams(searchParams, { replace: true });
      setShowSharing(true);
    }
  }, [searchParams, setSearchParams, setShowSharing]);

  useEffect(() => {
    let baseUrl = `${window.location.origin}/api/events`;
    if (process.env.NODE_ENV === 'development') {
      baseUrl = `${window.location.protocol}//${window.location.hostname}:4028/api/events`;
    }
    const sse = new EventSource(`${baseUrl}/authrequest/${authorization.id}`);
    sse.onmessage = (e) => {
      let auth = JSON.parse(e.data);
      if (auth) {
        setOneAuthorization(auth);
      }
    };
    sse.onerror = (error) => {
      console.log('sse error: ', error);
      sse.close();
    };
    return () => {
      sse.close();
    };
  }, []);

  let shareData = {
    title: 'Machtigingsaanvraag goedkeuren',
    text: `Zou je mij (${authorization.identityNP?.volledigeNaam}) kunnen machtigen voor '${authorization.description}' via onderstaande link?

`,
    url: `${window.location.origin}/authorizesomeone/evaluate/${authorization.id}`,
  };

  if (showSharing) {
    return (
      <Share
        authorizationID={authorization.id}
        setOneAuthorization={setOneAuthorization}
        evaluatorEmail={authorization.evaluatorEmail}
        shareData={shareData}
        setShowSharing={setShowSharing}
        setToast={setToast}
      />
    );
  }
  return (
    <Awaiting
      authorization={authorization}
      setOneAuthorization={setOneAuthorization}
      setToast={setToast}
      setShowSharing={setShowSharing}
      cancelAuthorizationRequest={cancelAuthorizationRequest}
    />
  );
}

function Rejected({ authorization, cancelAuthorizationRequest }) {
  return (
    <div className={styles.main}>
      <Steps current={3} total={3} />
      <div>
        <h1 className={styles.title}>Aanvraag afgewezen</h1>
        <p>
          <strong>{authorization?.evaluatedBy?.volledigeNaam}</strong> heeft je
          machtigingsaanvraag voor <strong>{authorization?.description}</strong>{' '}
          afgewezen.
        </p>
      </div>

      <ButtonContainer>
        <Button onClick={() => cancelAuthorizationRequest()}>Afronden</Button>
      </ButtonContainer>
    </div>
  );
}

function Approved({ authorization }) {
  return (
    <div className={styles.main}>
      <Steps current={4} total={4} />
      <div>
        <h1 className={styles.title}>Aanvraag goedgekeurd</h1>
        <p>
          <strong>{authorization?.evaluatedBy?.volledigeNaam}</strong> heeft je
          machtigingsaanvraag voor <strong>{authorization?.description}</strong>{' '}
          goedgekeurd.
        </p>
      </div>
      <div>
        <p>Voeg je machtiging toe aan de Yivi app.</p>
        <IrmaIssue authorizationID={authorization.id} />
      </div>
    </div>
  );
}

function NoAuthRequests() {
  let navigate = useNavigate();

  return (
    <div className={styles.main}>
      <Steps current={3} total={3} />
      <div>
        <h1 className={styles.title}>Machtigingsaanvraag niet gevonden</h1>
        <p>We hebben deze machtigingsaanvraag niet gevonden.</p>
      </div>

      <ButtonContainer>
        <Button
          onClick={() =>
            navigate({
              pathname: '..',
              search: window.location.search,
            })
          }
        >
          Verder
        </Button>
      </ButtonContainer>
    </div>
  );
}

export default function AwaitingContainer({
  setToast,
  authorizations,
  setOneAuthorization,
}) {
  let navigate = useNavigate();
  let params = useParams();
  let authorizationID = params.authorizationID;
  const [showDialog, setShowDialog] = useState(false);

  function doCancel() {
    axios
      .get(`/api/cancelauthorizationrequest/${authorizationID}`)
      .then(() => {
        setOneAuthorization(authorizationID);
        navigate('/');
      })
      .catch((error) => {
        console.log(error.response);
        window.location.href = '/';
      });
  }

  function cancelAuthorizationRequest(needsWarningMessage) {
    if (needsWarningMessage) return setShowDialog(true);
    doCancel();
  }

  function rendercontent(authorization) {
    if (!authorization) {
      return <NoAuthRequests />;
    } else if (authorization.status === 'APPROVED') {
      return (
        <Approved
          authorization={authorization}
          cancelAuthorizationRequest={cancelAuthorizationRequest}
        />
      );
    } else if (authorization.status === 'REJECTED') {
      return (
        <Rejected
          authorization={authorization}
          cancelAuthorizationRequest={cancelAuthorizationRequest}
        />
      );
    }
    return (
      <AwaitingAuthorization
        authorization={authorization}
        setOneAuthorization={setOneAuthorization}
        setToast={setToast}
        cancelAuthorizationRequest={cancelAuthorizationRequest}
      />
    );
  }

  let authorization = authorizations.find(
    (auth) => auth.id === authorizationID
  );

  return (
    <>
      {rendercontent(authorization)}
      {showDialog && (
        <Dialog onClose={() => setShowDialog(false)}>
          <div>
            <p>Weet je zeker dat je de aanvraag wil afbreken?</p>
            <ButtonContainer>
              <Button color="white" onClick={() => setShowDialog(false)}>
                Annuleren
              </Button>
              <Button onClick={doCancel}>Afbreken</Button>
            </ButtonContainer>
          </div>
        </Dialog>
      )}
    </>
  );
}
