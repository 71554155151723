import React from 'react';
import styles from './ContactDetails.module.css';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import Steps from '../generic/Steps';

export default function ContactDetails({ session, setSession }) {
  let navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      requestorEmail: session.requestorEmail,
    },
  });

  function onSubmit(data) {
    if (data?.requestorEmail) {
      setSession({
        ...session,
        requestorEmail: data.requestorEmail,
      });
      navigate({
        pathname: '../requestauthorization',
        search: window.location.search,
      });
    }
  }

  return (
    <div>
      <Steps current={1} total={4} />
      <h1 className={styles.title}>Contactgegevens</h1>
      <div>
        Als de machtigingsaanvraag is goedgekeurd, sturen we je een email op dit
        adres.
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ paddingTop: '24px' }}>
          <span style={{ fontWeight: '500' }}>Wat is jouw emailadres?</span>
        </div>
        <div className={styles.inputContainer}>
          <div>
            <input
              className={styles.inputText}
              type="email"
              {...register('requestorEmail', {
                required: {
                  value: true,
                  message: 'Emailadres is vereist',
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                  message: 'Geen juist emailadres',
                },
              })}
            />
          </div>
          <div className={styles.error}>{errors.requestorEmail?.message}</div>
        </div>
        <ButtonContainer>
          {location.key !== 'default' && (
            <Button
              color="white"
              onClick={() => {
                navigate(-1);
              }}
            >
              Terug
            </Button>
          )}
          <Button type="submit">Verder</Button>
        </ButtonContainer>
      </form>
    </div>
  );
}
