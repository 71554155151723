import React from 'react';
import styles from './Info.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import Step from '../generic/Step';

export default function AuthorizationOverview() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div>
      <h1>Machtiging toevoegen aan Yivi</h1>
      <p>
        Sta je <strong>niet</strong> ingeschreven in het KVK handelsregister,
        dan kun je een machtiging aanvragen bij de eigenaar of bestuurder, die{' '}
        <strong>wel</strong> staat ingeschreven namens je organisatie.
      </p>
      <p>Voeg in 4 stappen een machtiging toe aan Yivi:</p>
      <div className={styles.stepsContainer}>
        <Step count="1">Voeg je contactgegevens toe</Step>
        <Step count="2">Vraag een machtiging aan</Step>
        <Step count="3">Wacht op goedkeuring</Step>
        <Step count="4">Voeg je machtiging toe aan Yivi</Step>
      </div>
      <ButtonContainer>
        {location.key !== 'default' && (
          <Button
            color="white"
            onClick={() => {
              navigate(-1);
            }}
          >
            Terug
          </Button>
        )}
        <Button
          onClick={() => {
            navigate({
              pathname: '../contactdetails',
              search: window.location.search,
            });
          }}
        >
          Machtiging toevoegen
        </Button>
      </ButtonContainer>
    </div>
  );
}
