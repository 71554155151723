import React from 'react';
import axios from 'axios';
import { useNavigate, Navigate } from 'react-router-dom';
import EvaluatorEmail from './EvaluatorEmail';

export default function SendRequest({
  setOneAuthorization,
  setToast,
  session,
}) {
  const navigate = useNavigate();

  function onSubmit(data) {
    let formData = new FormData();
    formData.append('authorizationCode', session.authorizationCode);
    formData.append('requestorEmail', session.requestorEmail);
    formData.append('evaluatorEmail', data.evaluatorEmail);

    axios
      .post('/api/requestauthorization', formData)
      .then(({ data }) => {
        setOneAuthorization(data);
        navigate({
          pathname: `../awaitingauthorization/${data.id}`,
        });
      })
      .catch((error) => {
        console.log(error.response);
        setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
      });
  }

  if (!session.requestorEmail) {
    return (
      <Navigate
        to={{
          pathname: '../contactdetails',
          search: window.location.search,
        }}
        replace={true}
      />
    );
  }

  if (!session.authorizationCode) {
    return (
      <Navigate
        to={{
          pathname: '../requestauthorization',
          search: window.location.search,
        }}
        replace={true}
      />
    );
  }

  return (
    <EvaluatorEmail
      onSubmit={onSubmit}
      back={() => {
        navigate(-1);
      }}
    />
  );
}
