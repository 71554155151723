import React from 'react';
import styles from './View.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import moment from 'moment';

function Auth({ authorization }) {
  let navigate = useNavigate();

  let dateField = authorization.expiryDate;
  if (authorization.status === 'AWAITING_APPROVAL') {
    dateField = authorization.requestDate;
  }
  let dd = moment(dateField).calendar();
  //   let dd = moment(authorization.requestDate).format('l');

  return (
    <tr
      onClick={(event) => {
        event.preventDefault();
        navigate({
          pathname: `../awaitingauthorization/${authorization.id}`,
          search: window.location.search,
        });
      }}
    >
      <td> {authorization.description}</td>
      {/* <td> {authorization.status}</td> */}
      <td> {dd}</td>
      <td>⟩</td>
    </tr>
  );
}

function AuthBlock({ authorizations, title, isPending }) {
  if (!authorizations.length) {
    return <p>Je hebt momenteel geen lopende machtigingsaanvragen</p>;
  }
  return (
    <div className={styles.authBlockMain}>
      <div className={styles.title}>{title}</div>
      <table>
        <thead>
          <tr>
            {/* <td>Soort machtiging</td> */}
            <td></td>
            {/* <td>Status</td> */}
            <td>{isPending ? 'Aangevraagd op' : 'Loopt af op'}</td>
          </tr>
        </thead>
        <tbody>
          {authorizations.map((auth) => (
            <Auth key={auth.id} authorization={auth} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default function View({ authorizations }) {
  let navigate = useNavigate();
  const location = useLocation();

  let pendingAuthorizationRequests = authorizations.filter(
    (auth) => auth.status === 'AWAITING_APPROVAL'
  );
  let evaluatedAuthorization = authorizations.filter(
    (auth) => auth.status === 'APPROVED'
  );
  // let rejectedAuthorization = authorizations.filter(
  //   (auth) => auth.status === 'REJECTED'
  // );

  return (
    <div className={styles.main}>
      <h1>Mijn machtigingen</h1>
      <AuthBlock
        authorizations={pendingAuthorizationRequests}
        title="Lopende machtigingsaanvragen"
        isPending
      />
      {!!evaluatedAuthorization.length && (
        <AuthBlock
          authorizations={evaluatedAuthorization}
          title="Aan mij toegekende machtigingen"
        />
      )}

      {/* {!!rejectedAuthorization.length && (
        <AuthBlock
          authorizations={evaluatedAuthorization}
          title="Aan jou toegekende machtigingen"
        />
      )} */}

      <ButtonContainer>
        {location.key !== 'default' && (
          <Button
            color="white"
            onClick={() => {
              navigate(-1);
            }}
          >
            Terug
          </Button>
        )}
        <Button
          onClick={() => {
            navigate({
              pathname: `../contactdetails`,
              search: window.location.search,
            });
          }}
        >
          Nieuwe machting aanvragen
        </Button>
      </ButtonContainer>
    </div>
  );
}
