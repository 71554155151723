import React, { useCallback } from 'react';
import styles from './PickOrganization.module.css';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import axios from 'axios';
import debounce from 'lodash/debounce';
import organizationIcon from '../../images/organization.png';

function OrganizationView({ organizationData: org }) {
  if (!org) return null;

  return (
    <div className={styles.main}>
      <div className={styles.organizationTitle}>
        <img src={organizationIcon} alt="" />
        <div style={{ paddingLeft: '12px' }}>{org.name}</div>
      </div>
      <table>
        <tbody>
          <tr style={{ paddingTop: '12px' }}>
            <td>Adres</td>
            <td>
              <div>
                <div>{`${org.address?.street} ${org.address?.city}`}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>KVK nummer</td>
            <td>{org.organizationNumber}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const getAsyncOptions = (inputValue) => {
  return axios
    .get(`/api/companies/search?term=${encodeURIComponent(inputValue)}`)
    .then((response) => {
      if (!response.data.data) return;
      return response.data.data.map((item) => ({
        ...item,
        value: item.organizationNumber,
        label: item.name,
      }));
    });
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={styles.selectOption}>
        <span>{props.data.name}</span>
        <span>{props.data.organizationNumber}</span>
      </div>
    </components.Option>
  );
};

export default function PickOrganization({
  organizationData,
  setOrganizationData,
}) {
  let navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getAsyncOptions(inputText).then((options) => callback(options));
    }, 1200),
    []
  );

  return (
    <div>
      <h1 className={styles.title}>Vind je organisatie</h1>
      <div className={styles.inputContainer}>
        <div className={styles.inputLabel}>KVK nummer of handelsnaam</div>

        <AsyncSelect
          // autoFocus
          blurInputOnSelect={true}
          captureMenuScroll
          isClearable={true}
          // controlShouldRenderValue={false}
          loadOptions={loadOptions}
          onChange={(organizationData) => {
            setOrganizationData(organizationData);
            if (organizationData?.organizationNumber) {
              searchParams.set(
                'kvknummer',
                organizationData.organizationNumber
              );
            } else {
              searchParams.delete('kvknummer');
            }
            setSearchParams(searchParams, { replace: true });
          }}
          placeholder=""
          noOptionsMessage={() => 'niks gevonden'}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option,
          }}
          openMenuOnClick={false}
          openMenuOnFocus={false}
          hideSelectedOptions={false}
        />
      </div>

      <OrganizationView organizationData={organizationData} />

      <ButtonContainer>
        {location.key !== 'default' && (
          <Button
            color="white"
            onClick={() => {
              navigate(-1);
            }}
          >
            Terug
          </Button>
        )}
        {organizationData && (
          <Button
            onClick={() => {
              searchParams.set(
                'kvknummer',
                organizationData.organizationNumber
              );
              navigate({
                pathname: `../bevoegdheiduittreksel`,
                search: '?' + searchParams.toString(),
              });
            }}
          >
            Volgende
          </Button>
        )}
      </ButtonContainer>
    </div>
  );
}
