import React, { useState, useEffect } from 'react';
import styles from './App.module.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import PickAuthorization from './components/PickAuthorization';
import GetAuthorizationProtected from './components/get-authorization/Protected';
import AuthorizeSomeoneProtected from './components/authorize-someone/Protected';
import Toast from './components/generic/Toast';
import Header from './components/generic/Header';
import axios from 'axios';

export default function App() {
  const [toast, setToast] = useState();
  const [session, setSession] = useState({ loading: true });
  const [authorizations, setAuthorizations] = useState({ loading: true });
  const [sessionKVK, setSessionKVK] = useState({ loading: true });
  const [authorizationsKVK, setAuthorizationsKVK] = useState({ loading: true });
  const [relyingParties, setRelyingParties] = useState();

  function fetchAuthorizations() {
    axios
      .get(`/api/authorizations`)
      .then(({ data }) => {
        setAuthorizations(data);
      })
      .catch((error) => {
        console.log(error.message);
        setToast({
          message: 'Er ging iets mis met het laden van machtigingen.',
          color: '#cc2020',
        });
        setAuthorizations([]);
      });
  }

  function fetchAuthorizationsKVK() {
    axios
      .get(`/api/kvk/authorizations`)
      .then(({ data }) => {
        setAuthorizationsKVK(data);
      })
      .catch((error) => {
        console.log(error.message);
        setToast({
          message: 'Er ging iets mis met het laden van machtigingen.',
          color: '#cc2020',
        });
        setAuthorizationsKVK([]);
      });
  }

  function setOneAuthorization(authorization) {
    let newAuths;
    if (typeof authorization === 'string') {
      // delete authorization
      let authorizationID = authorization;
      newAuths = authorizations.filter((auth) => auth.id !== authorizationID);
    } else {
      newAuths = authorizations.filter((auth) => auth.id !== authorization.id);
      newAuths.push(authorization);
    }
    setAuthorizations(newAuths);
  }

  function setOneAuthorizationKVK(authorization) {
    let newAuths = authorizationsKVK.filter(
      (auth) => auth.id !== authorization.id
    );
    newAuths.push(authorization);
    setAuthorizationsKVK(newAuths);
  }

  useEffect(() => {
    axios
      .get(`/api/session`)
      .then(({ data }) => {
        setSession(data);
        fetchAuthorizations();
      })
      .catch((error) => {
        // console.log(error.message);
        // setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
        setSession(null);
      });

    axios
      .get(`/api/kvk/session`)
      .then(({ data }) => {
        setSessionKVK(data);
        fetchAuthorizationsKVK();
      })
      .catch((error) => {
        // console.log(error.message);
        // setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
        setSessionKVK(null);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/api/relyingparties`)
      .then(({ data }) => {
        setRelyingParties(data);
      })
      .catch((error) => {
        console.log(error.response);
        setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
      });
  }, []);

  if (session?.loading || sessionKVK?.loading)
    return <div>loading session...</div>;

  if (!relyingParties) return <div>loading relying parties...</div>;

  return (
    <div className={styles.main}>
      <Header
        session={session}
        sessionKVK={sessionKVK}
        authorizations={authorizations}
      />
      {/* <div>{document.referrer}</div> */}
      <div className={styles.content}>
        <Routes>
          <Route
            path=""
            element={
              <Home
                relyingParties={relyingParties}
                session={session}
                setSession={setSession}
              />
            }
          ></Route>
          <Route
            path="pickauthorization"
            element={<PickAuthorization relyingParties={relyingParties} />}
          ></Route>
          <Route
            path="authorizesomeone/*"
            element={
              <AuthorizeSomeoneProtected
                session={sessionKVK}
                setSession={setSessionKVK}
                authorizations={authorizationsKVK}
                setOneAuthorization={setOneAuthorizationKVK}
                fetchAuthorizations={fetchAuthorizationsKVK}
                setToast={setToast}
                relyingParties={relyingParties}
              />
            }
          ></Route>
          <Route
            path="getauthorization/*"
            element={
              <GetAuthorizationProtected
                session={session}
                setSession={setSession}
                authorizations={authorizations}
                setOneAuthorization={setOneAuthorization}
                fetchAuthorizations={fetchAuthorizations}
                setToast={setToast}
                relyingParties={relyingParties}
              />
            }
          ></Route>
        </Routes>
      </div>
      <Toast toast={toast} setToast={setToast}></Toast>
    </div>
  );
}
