import React from 'react';
import styles from './IrmaQR.module.css';
import appStore from '../../images/app-store.png';
import googlePlay from '../../images/google-play.png';

export default function IrmaQR({ includePermissionsManager }) {
  return (
    <div className={styles.main}>
      <div className={styles.irma}>
        <div className={styles.irmaContainer}>
          <div id="irma-web-form"></div>
        </div>
      </div>
      <div className={styles.downloadContainer}>
        <div className={styles.download}>
          <div className={styles.header}>Yivi-app downloaden</div>
          <p>
            Heb je nog geen Yivi-app? Download deze dan gratis vanuit de
            app-store.
          </p>
          <div className={styles.downloadButtonContainer}>
            <a
              className={styles.downloadButton}
              href="https://apps.apple.com/nl/app/irma-authenticatie/id1294092994"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStore} alt="app store" />
            </a>
            <a
              className={styles.downloadButton}
              href="https://play.google.com/store/apps/details?id=org.irmacard.cardemu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlay} alt="google play" />
            </a>
          </div>
        </div>
        {includePermissionsManager && (
          <div className={styles.requestAuthorization}>
            <div className={styles.header}>Machtigingenbeheerder</div>
            <p>
              Sta je <strong>niet</strong> bij de KVK ingeschreven als eigenaar
              of bestuurder van je organisatie, dan kun je hier ook inloggen als
              machtigingenbeheerder.
            </p>
            <p>
              Ben je nog geen machtigingenbeheerder, dan kun je dat aanvragen
              bij de eigenaar of bestuurder van je organisatie via onderstaande
              link.
            </p>
            <a
              href="#id"
              onClick={(event) => {
                event.preventDefault();
                // navigate(
                //   '/getauthorization/requestauthorization?authorization=signicat_machtigingenbeheerder'
                // );
                window.open(
                  '/getauthorization/contactdetails?authorization=signicat_machtigingenbeheerder',
                  '_blank'
                );
              }}
            >
              Machtigingenbeheerder worden
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
