import React from 'react';
import styles from './Step.module.css';

export default function Step({ children, count }) {
    let isFirst = (count === '1')
    return (
      <>
        {!isFirst && <div className={styles.stepDivider} />}
        <div className={styles.step + (isFirst ? '' : ' ' + styles.nextStep)}>
          <div className={styles.stepCount}>{count}</div>
          <div className={styles.stepText}>{children}</div>
        </div>
      </>
    );
  }
  