import React from 'react';
import styles from './Steps.module.css';

export default function Steps({ current, total = 5 }) {
  let steps = [...Array(total).keys()];
  steps = steps.map((s) => ++s);

  return (
    <div className={styles.main}>
      {steps.map((step, index) => {
        let elArr = [];
        let cn = styles.step;
        if (step === current) {
          cn += ' ' + styles.current;
        }
        let toDraw = step;
        if (step < current) {
          toDraw = '✓';
        }
        elArr.push(
          <div key={index * 2} className={cn}>
            {toDraw}
          </div>
        );
        if (step <= steps.length - 1) {
          elArr.push(<div key={index * 2 + 1} className={styles.line} />);
        }
        return elArr;
      })}
    </div>
  );
}
