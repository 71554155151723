import React from 'react';
import styles from './PoweredBy.module.css';

export default function PoweredBy() {
  return (
    <div className={styles.main}>
      {/* <p>KVK gegevens worden opgehaald via Signicat.</p> */}
      <p className={styles.poweredby}>
        Powered by <strong>Signicat</strong>: identiteitsoplossingen voor een
        vertrouwde digitale wereld.
      </p>
    </div>
  );
}
