import React from 'react';
import styles from './RequestAuthorization.module.css';
import Steps from '../generic/Steps';
import Button from '../generic/Button';
import {
  useNavigate,
  useSearchParams,
  useLocation,
  Navigate,
} from 'react-router-dom';
import axios from 'axios';
import ButtonContainer from '../generic/ButtonContainer';
import { isMobile } from 'react-device-detect';
import RelyingParties from '../generic/RelyingParties';

export default function RequestAuhorization({
  setToast,
  setOneAuthorization,
  session,
  setSession,
  relyingParties,
}) {
  let navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  let authorizationCode = searchParams.get('authorization');

  function requestAuthorization() {
    if (isMobile && navigator.share) {
      let formData = new FormData();
      formData.append('authorizationCode', authorizationCode);
      formData.append('requestorEmail', session.requestorEmail);

      axios
        .post('/api/requestauthorization', formData)
        .then(({ data }) => {
          setOneAuthorization(data);
          navigate({
            pathname: `../awaitingauthorization/${data.id}`,
            search: '?share=true',
          });
        })
        .catch((error) => {
          console.log(error.response);
          setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
        });
    } else {
      if (authorizationCode) {
        setSession({
          ...session,
          authorizationCode,
        });
        navigate({
          pathname: '../sendrequest',
          search: window.location.search,
        });
      }
    }
  }

  if (!session.requestorEmail) {
    return (
      <Navigate
        to={{
          pathname: '../contactdetails',
          search: window.location.search,
        }}
        replace={true}
      />
    );
  }

  if (authorizationCode) {
    let selectedAuthType;
    let selectedRP;
    relyingParties.forEach((rp) =>
      rp.authorizationTypes.forEach((at) => {
        if (at.code === authorizationCode) {
          selectedAuthType = at;
          selectedRP = rp;
          return;
        }
      })
    );

    return (
      <div>
        <h1>Machtiging aanvragen</h1>

        <div>
          {authorizationCode === 'signicat_machtigingenbeheerder' ? (
            <p>
              Vraag een machtiging aan voor{' '}
              <strong>{selectedAuthType.description}</strong> bij de eigenaar of
              bestuurder van je organisatie.
            </p>
          ) : (
            <p>
              Vraag een machtiging aan voor{' '}
              <strong>
                {selectedAuthType.description} bij {selectedRP.naam}
              </strong>{' '}
              bij de eigenaar of bestuurder van je organisatie, of bij de
              machtigingenbeheerder van je organisatie.
            </p>
          )}

          <div style={{ padding: '12px 0 24px' }}>
            <a
              href="#id"
              onClick={(event) => {
                event.preventDefault();
                searchParams.delete('authorization');
                setSearchParams(searchParams);
              }}
            >
              Kies een andere machtiging
            </a>
          </div>
        </div>
        <ButtonContainer>
          {location.key !== 'default' && (
            <Button
              color="white"
              onClick={() => {
                navigate(-1);
              }}
            >
              Terug
            </Button>
          )}
          {searchParams.get('authorization') && (
            <Button onClick={requestAuthorization}>Aanvraag versturen</Button>
          )}
        </ButtonContainer>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <Steps current={2} total={4} />

      <RelyingParties
        relyingParties={relyingParties}
        title={'Kies een machtiging'}
      />

      <ButtonContainer>
        {location.key !== 'default' && (
          <Button
            color="white"
            onClick={() => {
              navigate(-1);
            }}
          >
            Terug
          </Button>
        )}
      </ButtonContainer>
    </div>
  );
}
