import React, { useEffect } from 'react';
import yivi from '@privacybydesign/yivi-frontend';
import { useNavigate } from 'react-router-dom';

export default function IrmaIssue({ authorizationID }) {
  let navigate = useNavigate();

  useEffect(() => {
    let options = {
      debugging: false,
      element: '#irma-web-form',
      state: {
        polling: {
          endpoint: 'status',
          interval: 1500,
          startState: 'INITIALIZED',
        },
      },
      session: {
        start: {
          url: (o) =>
            `${o.url}/api/session/issue${
              authorizationID ? `authorization/${authorizationID}` : ''
            }`,
          credentials: 'include',
        },
        mapping: {
          sessionPtr: (r) => r.sessionPtr,
          frontendRequest: (r) => r.frontendRequest,
        },
        result: false,
      },
    };

    const yiviInstance = yivi.newWeb(options);
    yiviInstance
      .start()
      .then(() => {
        navigate({
          pathname: authorizationID ? `../successauthorization` : `../success`,
          search: window.location.search,
        });
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  }, []);

  return <div id="irma-web-form"></div>;
}
