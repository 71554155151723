import React, { useState } from 'react';
import styles from './View.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../generic/Button';
import ButtonContainer from '../generic/ButtonContainer';
import Dialog from '../generic/Dialog';
import moment from 'moment';

function Auth({ authorization, setShowNotWorkingDialog }) {
  let dateField = authorization.expiryDate;
  if (authorization.status === 'AWAITING_APPROVAL') {
    dateField = authorization.requestDate;
  }
  let dd = moment(dateField).calendar();

  return (
    <tr
    // onClick={(event) => {
    //   event.preventDefault();
    //   navigate({
    //     pathname: `../awaitingauthorization/${authorization.id}`,
    //     search: window.location.search,
    //   });
    // }}
    >
      <td>{authorization.description}</td>
      <td>{dd}</td>
      <td>{authorization.evaluatedBy?.volledigeNaam}</td>
      <td>
        <Button
          color="white"
          small
          onClick={() => setShowNotWorkingDialog(true)}
        >
          intrekken
        </Button>
      </td>
    </tr>
  );
}

function AuthBlock({ allAuthorizations, title }) {
  const [showNotWorkingDialog, setShowNotWorkingDialog] = useState();

  let authorizationsByName = allAuthorizations.reduce((org, auth) => {
    if (!org[auth.identityNP.volledigeNaam]) {
      org[auth.identityNP.volledigeNaam] = [auth];
    } else {
      org[auth.identityNP.volledigeNaam].push(auth);
    }
    return org;
  }, {});

  if (!allAuthorizations.length) {
    return <p>Er zijn geen machtigingen toegekend</p>;
  }
  return (
    <div className={styles.authBlockMain}>
      <div className={styles.title}>{title}</div>

      {Object.keys(authorizationsByName).map((name) => {
        let authorizations = authorizationsByName[name];
        return (
          <div className={styles.authContainer} key={name}>
            <div className={styles.name}>
              <strong>{name}</strong>
            </div>
            <table>
              <thead>
                <tr>
                  <td></td>
                  <td>Loopt af op</td>
                  <td>Afgegeven door</td>
                </tr>
              </thead>
              <tbody>
                {authorizations.map((auth) => (
                  <Auth
                    key={auth.id}
                    authorization={auth}
                    setShowNotWorkingDialog={setShowNotWorkingDialog}
                  />
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
      {showNotWorkingDialog && (
        <Dialog onClose={() => setShowNotWorkingDialog(false)}>
          <div>
            <p>Intrekken van machtigingen werkt nog niet.</p>
            <ButtonContainer>
              <Button onClick={() => setShowNotWorkingDialog(false)}>OK</Button>
            </ButtonContainer>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default function View({ session, authorizations }) {
  let navigate = useNavigate();
  let location = useLocation();

  let allApprovedAuthorizations = authorizations.filter(
    (auth) => auth.status === 'APPROVED'
  );

  return (
    <div className={styles.main}>
      <h2>Machtigingen voor {session.identityKVK.naam}</h2>
      <AuthBlock
        allAuthorizations={allApprovedAuthorizations}
        title={`Toegekend aan:`}
      />

      <ButtonContainer>
        {location.key !== 'default' && (
          <Button
            color="white"
            onClick={() => {
              navigate(-1);
            }}
          >
            Terug
          </Button>
        )}
        <Button
          onClick={() => {
            navigate({
              pathname: `./invite`,
              search: window.location.search,
            });
          }}
        >
          Nieuwe machting toekennen
        </Button>
      </ButtonContainer>
    </div>
  );
}
