import React from 'react';
import styles from './Invite.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ButtonContainer from '../generic/ButtonContainer';
import Button from '../generic/Button';

export default function Invite({ session }) {
  let navigate = useNavigate();
  const location = useLocation();
  let url = `${window.location.origin}/getauthorization/infoauthorization`;

  return (
    <div className={styles.main}>
      <h1>Iemand machtigen voor {session.identityKVK.naam}</h1>
      <p>
        Met onderstaande link kun je iemand uitnodigen om een machtiging aan te
        vragen. Stuur deze link naar de medewerker, die je wil machtigen.
      </p>
      <div className={styles.inputContainer}>
        <input className={styles.inputText} type="text" defaultValue={url} />
        {navigator.clipboard && (
          <Button
            style={{ marginLeft: '12px' }}
            color="white"
            onClick={() => {
              navigator.clipboard.writeText(url);
            }}
          >
            Kopieer
          </Button>
        )}
      </div>

      <p>
        Zodra je werknemer de machtiging heeft aangevraagd, ontvang je van
        hem/haar een bericht om de machtiging goed te keuren.
      </p>
      <ButtonContainer>
        {location.key !== 'default' && (
          <Button
            color="white"
            onClick={() => {
              navigate(-1);
            }}
          >
            Terug
          </Button>
        )}
      </ButtonContainer>
    </div>
  );
}
