import React from 'react';
import styles from './Home.module.css';
import Button from './generic/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import hand1 from '../images/machtigen.svg';
import hand2 from '../images/gemachtigd_worden.svg';

export default function Home({ relyingParties, session, setSession }) {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgImage}>
        <div className={styles.bgImageOverlay}></div>
      </div>
      <div className={styles.main}>
        <h1>
          Machtig <br />
          je werknemer
        </h1>

        <div className={styles.blockContainer}>
          <div className={styles.innerBlockContainer}>
            <div className={styles.block}>
              <div className={styles.topBlock}>
                <div>
                  <img src={hand2} alt="" />
                </div>
                <div>
                  <div className={styles.headerInBlock}>
                    Machtiging aanvragen
                  </div>
                  <p>bij de eigenaar of een bestuurder van je organisatie</p>
                </div>
              </div>

              <Button
                onClick={() => {
                  searchParams.set('inhr', 'nee');
                  navigate({
                    pathname: '/getauthorization',
                    search: '?' + searchParams.toString(),
                  });
                }}
              >
                Gemachtigd worden
              </Button>
            </div>
            <div className={styles.block}>
              <div className={styles.topBlock}>
                <div>
                  <img src={hand1} alt="" />
                </div>
                <div>
                  <div className={styles.headerInBlock}>Iemand machtigen</div>
                  <p>voor je organisatie</p>
                </div>
              </div>

              <Button
                onClick={() => {
                  navigate({
                    pathname: '/authorizesomeone/view',
                    search: window.location.search,
                  });
                }}
              >
                Iemand machtigen
              </Button>
            </div>
          </div>
          <div className={styles.bevoegdBlock}>
            <div className={styles.topBlock}>
              <div>
                <div className={styles.headerInBlock}>
                  Bevoegdheidsgegevens ophalen
                </div>
                <p>
                  Voeg je bevoegdheid voor je organisatie toe aan je wallet app
                </p>
              </div>
            </div>
            <Button
              color="white"
              onClick={() => {
                searchParams.set('inhr', 'ja');
                navigate({
                  pathname: '/getauthorization',
                  search: '?' + searchParams.toString(),
                });
              }}
            >
              KVK kaartje toevoegen
            </Button>
          </div>
        </div>
        <div className={styles.subBlocksContainer}>
          <div className={styles.subBlock}>
            <div className={styles.headerInBlock}>Hoe werkt machtigen?</div>
            <p>
              Als eigenaar of bestuurder van je organisatie wil je taken kunnen
              delegeren naar je medewerkers. Maar om bijvoorbeeld je
              belastingzaken te laten regelen door een medewerker, dan moet die
              medewerker wel gemachtigd zijn om namens je organisatie die taken
              te kunnen uitvoeren bij de belastingdienst.
            </p>
            <p>
              Via deze website machtig je je medewerker veilig en makkelijk.
            </p>
            <Button
              onClick={() => {
                navigate({
                  pathname: '/pickauthorization',
                  search: window.location.search,
                });
              }}
            >
              Overzicht van machtigingen
            </Button>
          </div>
          <div className={styles.subBlock}>
            <div className={styles.headerInBlock}>
              Machtigingenbeheerder worden
            </div>
            <p>
              In grote organisaties heeft de eigenaar of bestuurder niet de tijd
              om alle machtigingsaanvragen te behandelen. Zij kunnen
              machtigingenbeheerders aanwijzen, om deze taak te laten overnemen.
            </p>
            <p>
              Om machtigingenbeheerder te worden vraag je via onderstaande knop,
              eenmalig een machtiging aan om 'medewerkers te kunnen machtigen'
              bij de eigenaar of bestuurder van je organisatie.
            </p>
            <Button
              onClick={() => {
                navigate('/getauthorization/requestauthorization?authorization=signicat_machtigingenbeheerder');
              }}
            >
              Machtigingenbeheerder worden
            </Button>
          </div>
          {/* <div className={styles.subBlock}>
            <div className={styles.headerInBlock}>
              Veel aangevraagde machtigingen
            </div>
            <RelyingParties relyingParties={relyingParties} />
            <Button
              onClick={() => {
                navigate({
                  pathname: '/pickauthorization',
                  search: window.location.search,
                });
              }}
            >
              Alle organisaties
            </Button>
          </div> */}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <h2>
            Machtig <br />
            je werknemer
          </h2>
          <div className={styles.footerLinks}>
            <div>
              <a
                href="#id"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Privacy
              </a>
            </div>
            <div>
              <a
                href="#id"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Toegankelijkheid
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
