import React, { useEffect, useState } from 'react';
import styles from './BevoegdheidUittreksel.module.css';
import axios from 'axios';
import Button from '../generic/Button';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import ButtonContainer from '../generic/ButtonContainer';
import IrmaIssue from './IrmaIssue';

function InTradeRegister({ bevoegdheidExtract, bevoegdheidExtractMother }) {
  let navigate = useNavigate();

  let type = bevoegdheidExtract?.matchedFunctionaris?.typeFunctionaris;
  let functie = bevoegdheidExtract?.matchedFunctionaris?.functie;
  if (bevoegdheidExtractMother) {
    type = bevoegdheidExtractMother.matchedFunctionaris.typeFunctionaris;
    functie = bevoegdheidExtractMother.matchedFunctionaris.functie;
  }
  if (type) type = type.toLowerCase();
  if (functie) functie = functie.toLowerCase();

  return (
    <div>
      <h1>Je staat in het KVK Handelsregister</h1>
      <p>
        Je staat ingeschreven in het KVK Handelsregister als{' '}
        <strong>{functie || type || 'eigenaar of bestuurder'}</strong> van{' '}
        <strong>
          {bevoegdheidExtract.naam || bevoegdheidExtract.kvkNummer}
        </strong>
        {bevoegdheidExtractMother && (
          <span>
            {' '}
            via{' '}
            <strong>
              {bevoegdheidExtractMother.naam ||
                bevoegdheidExtractMother.kvkNummer}
            </strong>
          </span>
        )}
        .
      </p>
      <div>
        <p>Voeg je KVK bevoegdheidsgegevens toe aan de Yivi app.</p>
        <IrmaIssue />
        <ButtonContainer>
          <Button color="white" onClick={() => navigate('..')}>
            Annuleren
          </Button>
        </ButtonContainer>
      </div>
    </div>
  );
}

function NotInMother({ bevoegdheidExtractMother }) {
  let navigate = useNavigate();

  let isInMother = bevoegdheidExtractMother?.matchedFunctionaris;
  let bevoegdThroughMother = isInMother?.interpretatie?.isBevoegd === 'Ja';

  let motherMessage;
  if (bevoegdheidExtractMother) {
    if (!bevoegdThroughMother) {
      motherMessage = (
        <>
          <p>
            {`Je staat ingeschreven in het handelsregister als functionaris van `}
            <strong>
              {bevoegdheidExtractMother.naam ||
                bevoegdheidExtractMother.kvkNummer}
            </strong>
            {` met een `}
            <strong>beperkte</strong>
            {` bevoegdheid. Je dient `}
            <strong>onbeperkt</strong>
            {` bevoegd te zijn voor de moederorganisatie om een Yivi kaartje te kunnen verkrijgen.`}
          </p>
          <p>
            Om dit op te lossen, kun je jezelf laten registreren bij de KVK als
            gevolmachtigde van de moederorganisatie, met een volledige volmacht.
          </p>
        </>
      );
    }
    if (!isInMother) {
      motherMessage = (
        <p>
          {`Je staat `}
          <strong>niet</strong>
          {` ingeschreven in het KVK handelsregister als eigenaar of bestuurder van `}
          <strong>
            {bevoegdheidExtractMother.naam ||
              bevoegdheidExtractMother.kvkNummer}
          </strong>
        </p>
      );
    }
  }

  return (
    <div>
      <h1>Je bent niet bevoegd voor de moederorganisatie</h1>
      {motherMessage}
      <ButtonContainer>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          Terug
        </Button>
      </ButtonContainer>
    </div>
  );
}

function NotInTradeRegister({ bevoegdheidExtract }) {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [kvkNummerMother, setKvkNummerMother] = useState();

  let functionarissen = bevoegdheidExtract.functionarissen;

  let rechtspersoonFunctionarissen =
    bevoegdheidExtract.rechtspersoonFunctionarissen;

  return (
    <div>
      <h1>Je staat niet in het KVK Handelsregister</h1>
      <p>
        {`Je staat `}
        <strong>niet</strong>
        {` ingeschreven in het KVK Handelsregister als eigenaar of bestuurder van `}
        <strong>
          {bevoegdheidExtract.naam || bevoegdheidExtract.kvkNummer}
        </strong>
        .
      </p>

      {functionarissen && functionarissen.length && (
        <>
          <p>
            {`De volgende personen staan ingeschreven in het KVK Handelsregister namens ${
              bevoegdheidExtract.naam || bevoegdheidExtract.kvkNummer
            }:`}
          </p>
          <div>
            <ul>
              {functionarissen ? (
                functionarissen.map((functionaris) => {
                  let f = functionaris.functietitel || functionaris.functie;
                  let fStr = f ? ` (${f})` : '';
                  // let b =
                  //   functionaris.soortBevoegdheid || functionaris.typeVolmacht;
                  // let bStr = b ? ` - ${b}` : '';
                  return (
                    <li key={functionaris.volledigeNaam}>
                      <span style={{ fontWeight: '500' }}>
                        {functionaris.volledigeNaam}
                      </span>
                      <span>{fStr}</span>
                      {/* <span>{bStr}</span> */}
                      {functionaris.interpretatie.isBevoegd === 'Ja' && (
                        <span>{` -  ✅`}</span>
                      )}
                    </li>
                  );
                })
              ) : (
                <div
                  style={{
                    fontStyle: 'italic',
                    opacity: '0.7',
                    fontSize: '0.9em',
                  }}
                >
                  Geen natuurlijke personen op het KVK uittreksel gevonden
                </div>
              )}
            </ul>
          </div>
          <div>
            <div>
              Je kunt bij een van bovengenoemde personen een machtiging
              aanvragen.
            </div>
            <p>
              <strong>Let op</strong>: Alleen personen met een groen vinkje
              achter hun naam kunnen makkelijk een machtiging afgeven.
            </p>
            <ButtonContainer>
              <Button
                onClick={() => {
                  navigate({
                    pathname: '../infoauthorization',
                  });
                }}
              >
                Machtiging aanvragen
              </Button>
            </ButtonContainer>
          </div>
        </>
      )}

      {!!rechtspersoonFunctionarissen?.length && (
        <div>
          <p>
            {`De volgende `}
            <strong>rechtspersonen</strong>
            {` staan ingeschreven in het KVK Handelsregister als functionaris van ${
              bevoegdheidExtract.naam || bevoegdheidExtract.kvkNummer
            }:`}
          </p>
          <div style={{ paddingBottom: '24px' }}>
            {rechtspersoonFunctionarissen.map((rp) => {
              let f = rp.functie || rp.typeFunctionaris;
              return (
                <label
                  key={rp.kvkNummer + rp.typeFunctionaris}
                  className={styles.radioButton}
                  htmlFor={rp.kvkNummer}
                >
                  <div>
                    <input
                      type="radio"
                      id={rp.kvkNummer}
                      name={rp.kvkNummer}
                      value={rp.kvkNummer}
                      checked={rp.kvkNummer === kvkNummerMother}
                      onChange={() => setKvkNummerMother(rp.kvkNummer)}
                    />
                    <span style={{ fontWeight: '500', paddingLeft: '12px' }}>
                      {rp.naam}
                    </span>
                    <span>{` (${rp.kvkNummer}) - ${f}`}</span>
                  </div>
                </label>
              );
            })}
          </div>
          <div>
            Sta je in het KVK Handelsregister geregistreerd als eigenaar of
            bestuurder van een van bovenstaande organisaties? Kies dan je
            organisatie om alsnog je KVK bevoegdheidsgegevens te verkrijgen.
          </div>
          {kvkNummerMother && (
            <ButtonContainer>
              <Button
                disabled={!kvkNummerMother}
                onClick={() => {
                  searchParams.set('mother', kvkNummerMother);
                  navigate({
                    search: '?' + searchParams.toString(),
                  });
                }}
              >
                Check moederorganisatie
              </Button>
            </ButtonContainer>
          )}
        </div>
      )}
      <ButtonContainer>
        <Button color="white" onClick={() => navigate('..')}>
          Annuleren
        </Button>
      </ButtonContainer>
    </div>
  );
}

export default function BevoegdheidUittreksel({ session, setSession }) {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [serverError, setServerError] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    let kvkNummer = searchParams.get('kvknummer');
    if (kvkNummer) {
      let formData = new FormData();
      formData.append('kvkNummer', kvkNummer);

      let mother = searchParams.get('mother');
      if (mother) {
        formData.append('mother', mother);
      }

      setLoading(true);
      axios
        .post('/api/bevoegdheid', formData)
        .then(({ data }) => {
          setServerError(null);
          setSession(data);
        })
        .catch((error) => {
          console.log(error.response);
          let err = error?.response?.data?.error;
          setServerError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchParams]);

  if (!searchParams.get('kvknummer')) {
    return <Navigate to="../organization" replace />;
  }

  if (serverError) {
    let message = 'Geen uittreksel gevonden';
    let subMessage = 'Het is niet gelukt om het KVK uittreksel op te halen.';
    if (serverError === 'invalid-input') {
      message = 'Onjuiste gegevens';
      subMessage = 'We hebben niet de juiste gegevens van je ontvangen.';
    } else if (serverError === 'parse-error') {
      message = 'Leesfout';
      subMessage = 'Er ging iets mis met het lezen van het KVK uittreksel.';
    } else if (serverError === 'limit-exceeded') {
      message = 'Maximum overschreden';
      subMessage =
        'Je hebt het maximaal aantal opvragingen van organisatiegegevens overschreden voor vandaag. In het kader van de pilot is dit gelimiteerd. Probeer het later nog eens.';
    } else if (serverError === 'limit-exceeded-lt') {
      message = 'Maximum overschreden';
      subMessage =
        'Je hebt het maximaal aantal opvragingen van organisatiegegevens overschreden voor deze maand. In het kader van de pilot is dit gelimiteerd. Probeer het later nog eens.';
    }
    return (
      <div className={styles.main}>
        <h1>{message}</h1>
        <p>{subMessage}</p>
        <ButtonContainer>
          <Button color="white" onClick={() => navigate(-1)}>
            Terug
          </Button>
        </ButtonContainer>
      </div>
    );
  }

  if (loading) return <div>loading...</div>;
  if (!session.bevoegdheidResponse) return <div>Geen uittreksel gevonden</div>;

  let bevoegdheidExtract = session.bevoegdheidResponse.bevoegdheidUittreksel;
  let isIn = bevoegdheidExtract.matchedFunctionaris;

  let bevoegdheidExtractMother = session.mother?.bevoegdheidUittreksel;
  let isInMother = bevoegdheidExtractMother?.matchedFunctionaris;
  let bevoegdThroughMother = isInMother?.interpretatie?.isBevoegd === 'Ja';

  if (isIn) {
    return <InTradeRegister bevoegdheidExtract={bevoegdheidExtract} />;
  }
  if (session.mother) {
    if (bevoegdThroughMother) {
      return (
        <InTradeRegister
          bevoegdheidExtract={bevoegdheidExtract}
          bevoegdheidExtractMother={bevoegdheidExtractMother}
        />
      );
    } else {
      return (
        <NotInMother bevoegdheidExtractMother={bevoegdheidExtractMother} />
      );
    }
  }
  return <NotInTradeRegister bevoegdheidExtract={bevoegdheidExtract} />;
}
