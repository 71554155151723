import React, { useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import PickOrganization from './PickOrganization';
import axios from 'axios';

export default function Organization({ setToast, session, setSession }) {
  let [searchParams, setSearchParams] = useSearchParams();
  let kvkNummer = searchParams.get('kvknummer');
  let organizationData = session.organizationData;

  function setOrganizationData(organizationData) {
    setSession({ ...session, organizationData });
  }

  useEffect(() => {
    if (!kvkNummer) {
      setOrganizationData(null);
    } else if (!kvkNummer.match(/^[0-9]{8}$/)) {
      searchParams.delete('kvknummer');
      setSearchParams(searchParams, { replace: true });
      setOrganizationData(null);
    } else if (organizationData?.organizationNumber === kvkNummer) {
      // already loaded
    } else {
      axios
        .get(`/api/companies/search?term=${kvkNummer}`)
        .then(({ data }) => {
          let items = data.data;
          if (items && items[0]) {
            setOrganizationData(items[0]);
          } else {
            setSearchParams();
            setOrganizationData(null);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setSearchParams();
          setOrganizationData(null);
        });
    }
  }, []);

  return (
    <Routes>
      <Route
        path="organization"
        element={
          <PickOrganization
            setToast={setToast}
            organizationData={organizationData}
            setOrganizationData={setOrganizationData}
          />
        }
      ></Route>
    </Routes>
  );
}
