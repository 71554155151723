import React, { useEffect, useState } from 'react';
import styles from './Evaluate.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonContainer from '../generic/ButtonContainer';
import axios from 'axios';
import Button from '../generic/Button';

const periods = [
  {
    value: '14',
    label: '2 weken',
  },
  {
    value: '30',
    label: '1 maand',
  },
  {
    value: '91',
    label: '3 maanden',
  },
  {
    value: '183',
    label: '6 maanden',
  },
  {
    value: '365',
    label: '1 jaar',
  },
];

function Evaluate({ session, setToast, authorization, setOneAuthorization }) {
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [sendingEvaluation, setSendingEvaluation] = useState();

  function evaluateButtonClicked(evaluation) {
    let formData = new FormData();
    formData.append('evaluation', evaluation);
    formData.append('periodInDays', selectedPeriod);
    formData.append('authorizationID', authorization.id);
    setSendingEvaluation(true);
    axios
      .post('/api/kvk/evaluateauthorization', formData)
      .then(({ data }) => {
        setOneAuthorization(data);
      })
      .catch((error) => {
        console.log(error.response);
        setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
      })
      .finally(() => {
        setSendingEvaluation(false);
      });
  }

  return (
    <div className={styles.main}>
      <h1>Machtiging toekennen</h1>

      <p>
        Wil je <strong>{authorization?.identityNP?.volledigeNaam}</strong>{' '}
        machtigingen voor <strong>{authorization?.description}</strong> namens{' '}
        <strong>{session.identityKVK.naam}</strong>?
      </p>
      <div style={{ paddingTop: '6px' }}>Geldig voor</div>
      <select
        className={styles.select}
        value={selectedPeriod}
        onChange={(event) => setSelectedPeriod(event.target.value)}
      >
        <option value="">Maak je keuze</option>
        {periods.map((period) => (
          <option key={period.value} value={period.value}>
            {period.label}
          </option>
        ))}
      </select>

      <p>
        <strong>Let op</strong>: De machtiging kan nog niet tussentijds ingetrokken
        worden. Verleen dus de machtiging niet voor een te lange periode.
      </p>

      <ButtonContainer>
        <Button
          disabled={!!sendingEvaluation}
          color="white"
          onClick={() => evaluateButtonClicked('REJECTED')}
        >
          Afwijzen
        </Button>
        <Button
          disabled={!!sendingEvaluation || !selectedPeriod}
          onClick={() => evaluateButtonClicked('APPROVED')}
        >
          Machtiging toekennen
        </Button>
      </ButtonContainer>
    </div>
  );
}

function Evaluated({ authorization }) {
  let status = authorization?.status;
  let navigate = useNavigate();

  return (
    <div className={styles.main}>
      <h1>{`Machtigingsaanvraag ${
        status === 'APPROVED' ? 'goedgekeurd' : 'afgewezen'
      }`}</h1>
      <p>
        We hebben <strong>{authorization?.identityNP?.volledigeNaam}</strong>{' '}
        laten weten dat de machtigingsaanvraag is{' '}
        {status === 'APPROVED' ? 'goedgekeurd' : 'afgewezen'}.
      </p>
      <p>Je kan dit browservenster sluiten.</p>
      <p>
        <a
          href="#id"
          onClick={(event) => {
            event.preventDefault();
            navigate({
              pathname: '../view',
              search: window.location.search,
            });
          }}
        >
          Overzicht van toegekende machtigingen
        </a>
      </p>
    </div>
  );
}

export default function EvaluateContainer({
  session,
  setToast,
  authorizations,
  setOneAuthorization,
}) {
  let navigate = useNavigate();
  let params = useParams();
  let authorizationID = params.authorizationID;
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!authorizationID) return setLoading(false);

    axios
      .get(`/api/kvk/authorizations/${authorizationID}`)
      .then(({ data }) => {
        setOneAuthorization(data);
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response.status === 401) {
          setToast({
            message: 'Niet Geautoriseerd',
            color: '#cc2020',
          });
        } else {
          setToast({
            message: 'Machtigingsaanvraag niet gevonden.',
            color: '#cc2020',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <div>loading authorization request</div>;

  let authorization = authorizations.find(
    (auth) => auth.id === authorizationID
  );

  if (!authorization) {
    return (
      <div>
        <h1>Machtigingsaanvraag niet gevonden</h1>
        <p>We hebben de machtigingsaanvraag niet gevonden</p>
        <ButtonContainer>
          <Button color="white" onClick={() => navigate('/authorizesomeone')}>
            Afronden
          </Button>
        </ButtonContainer>
      </div>
    );
  }

  if (authorization.status === 'AWAITING_APPROVAL') {
    return (
      <Evaluate
        session={session}
        setToast={setToast}
        authorization={authorization}
        setOneAuthorization={setOneAuthorization}
      />
    );
  }

  return <Evaluated authorization={authorization} />;
}
