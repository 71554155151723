import React from 'react';
import Steps from '../generic/Steps';

export default function IssuanceSuccess({ isAuthorization }) {
  let message = 'Je KVK bevoegdheidsgegevens staan in de Yivi app';
  if (isAuthorization) {
    message = 'Je machtiging staat in de Yivi app';
  }

  return (
    <div>
      {isAuthorization && <Steps current={4} total={4} />}
      <div>
        <h1>Gelukt</h1>

        <p style={{ fontSize: '2.6em' }}>🎉</p>
        <p>{message}</p>
        <p>Je kan dit browservenster sluiten.</p>
      </div>
    </div>
  );
}
