import React from 'react';
import styles from './Dialog.module.css';

export default function Dialog({ children, onClose }) {
  return (
    <div className={styles.overlay}>
      <div className={styles.frame}>
        <a
          className={styles.closeButton}
          href="#id"
          onClick={(event) => {
            event.preventDefault();
            onClose();
          }}
        >
          ✕
        </a>
        <div className={styles.body}>{children}</div>
      </div>
    </div>
  );
}
