import React, { useState } from 'react';
import styles from './Header.module.css';
import logo from '../../images/logo-signicat.png';
import home from '../../images/home.png';
import person from '../../images/person.png';
import axios from 'axios';
import Dialog from './Dialog';
import Button from './Button';
import ButtonContainer from './ButtonContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import Hamburger from 'hamburger-react';

const Menu = ({ setShowDialog, userName, locHomeOrKVK }) => {
  const navigate = useNavigate();

  return (
    <>
      <a
        href="#id"
        onClick={(event) => {
          event.preventDefault();
          navigate('/');
        }}
      >
        <img className={styles.home} src={home} alt="home" />
        <span className={styles.homeLabel}>Home</span>
      </a>
      {locHomeOrKVK && (
        <>
          <a
            className={styles.menuItem}
            href="#id"
            onClick={(event) => {
              event.preventDefault();
              navigate({
                pathname: '/authorizesomeone',
                search: window.location.search,
              });
            }}
          >
            Mijn toegekende machtigingen
          </a>
          {/* <a
            href="#id"
            onClick={(event) => {
              event.preventDefault();
              navigate({
                pathname: '/authorizesomeone/register',
                search: window.location.search,
              });
            }}
          >
            Registreer machtiging
          </a> */}
        </>
      )}
      {setShowDialog && userName && (
        <a
          href="#id"
          onClick={(event) => {
            event.preventDefault();
            setShowDialog(true);
          }}
        >
          Log uit
        </a>
      )}
    </>
  );
};

const Header = ({ session, sessionKVK }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  let locKVK = location.pathname.includes('authorizesomeone');
  let locHomeOrNP = !location.pathname.includes('authorizesomeone');
  let locHomeOrKVK = !location.pathname.includes('getauthorization');

  let logoutMessage = 'Weet je zeker dat je wil uitloggen?';

  let userName;
  if (session?.identityNP && locHomeOrNP) {
    userName = session?.identityNP?.volledigeNaam;
  } else if (sessionKVK?.identityKVK && locHomeOrKVK) {
    userName = `${sessionKVK?.identityKVK?.volledigeNaam} namens ${sessionKVK?.identityKVK?.naam}`;
  }

  function logout() {
    axios
      .get(`/api${locKVK ? '/kvk' : ''}/logout`)
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.left}>
          <a
            href="#id"
            className={styles.logoContainer}
            onClick={(event) => {
              event.preventDefault();
              navigate({
                pathname: '/',
                search: window.location.search,
              });
            }}
          >
            <img className={styles.logo} src={logo} alt="logo" />
          </a>
          {/* <div className={styles.title}>
            {location.pathname === '/' ? 'Machtigingen' : ''}
          </div> */}

          <div className={styles.menu}>
            <Menu locHomeOrKVK={locHomeOrKVK} />
          </div>
        </div>

        <div className={styles.right}>
          {userName && (
            <div className={styles.usernameContainer}>
              <span>
                <img className={styles.person} src={person} alt="person" />
                <span className={styles.username}>{userName}</span>
              </span>
              <a
                href="#id"
                className={styles.logoutDesktop}
                onClick={(event) => {
                  event.preventDefault();
                  setShowDialog(true);
                }}
              >
                Log uit
              </a>
            </div>
          )}
          <div className={styles.hamburger}>
            <Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} size={20} />
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div
          className={styles.mobileMenuContainer}
          onClick={() => setIsMenuOpen(false)}
        >
          <div className={styles.mobileMenu}>
            <Menu setShowDialog={setShowDialog} userName={userName} locHomeOrKVK={locHomeOrKVK} />
          </div>
        </div>
      )}
      {showDialog && (
        <Dialog onClose={() => setShowDialog(false)}>
          <div>
            <p>{logoutMessage}</p>
            <ButtonContainer>
              <Button color="white" onClick={() => setShowDialog(false)}>
                Annuleren
              </Button>
              <Button onClick={logout}>Uitloggen</Button>
            </ButtonContainer>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Header;
