import React, { useEffect } from 'react';
import { useSearchParams, Routes, Route, Navigate } from 'react-router-dom';
import IrmaDisclose from './IrmaDisclose';
import axios from 'axios';
import Organization from './Organization';
import BevoegdheidUittreksel from './BevoegdheidUittreksel';
import AwaitingAuthorization from './AwaitingAuthorization';
import RequestAuthorization from './RequestAuthorization';
import GetAuthorizationHome from './Home';
import InfoAuthorization from './InfoAuthorization';
import View from './View';
import IssuanceSuccess from './IssuanceSuccess';
import ContactDetails from './ContactDetails';
import SendRequest from './SendRequest';

function Protected({
  setToast,
  session,
  setSession,
  authorizations,
  setOneAuthorization,
  relyingParties,
}) {
  if (authorizations?.loading) return <div>loading authorizations...</div>;

  return (
    <Routes>
      <Route
        path=""
        element={
          <GetAuthorizationHome
            authorizations={authorizations}
          />
        }
      ></Route>
      <Route
        path="info"
        element={<Navigate to="../bevoegdheiduittreksel" replace />}
      ></Route>
      <Route
        path="infoauthorization"
        element={
          <InfoAuthorization
            session={session}
            authorizations={authorizations}
          />
        }
      ></Route>
      <Route
        path="*"
        element={
          <Organization
            session={session}
            setSession={setSession}
            setToast={setToast}
          />
        }
      ></Route>
      <Route
        path="bevoegdheiduittreksel"
        element={
          <BevoegdheidUittreksel session={session} setSession={setSession} />
        }
      ></Route>
      <Route
        path="contactdetails"
        element={<ContactDetails session={session} setSession={setSession} />}
      ></Route>
      <Route
        path="requestauthorization"
        element={
          <RequestAuthorization
            session={session}
            setSession={setSession}
            setOneAuthorization={setOneAuthorization}
            relyingParties={relyingParties}
            setToast={setToast}
          />
        }
      ></Route>
      <Route
        path="sendrequest"
        element={
          <SendRequest
            session={session}
            setOneAuthorization={setOneAuthorization}
            setToast={setToast}
          />
        }
      ></Route>
      <Route
        path="awaitingauthorization/:authorizationID"
        element={
          <AwaitingAuthorization
            authorizations={authorizations}
            setOneAuthorization={setOneAuthorization}
            setToast={setToast}
          />
        }
      ></Route>
      <Route path="success" element={<IssuanceSuccess />}></Route>
      <Route
        path="successauthorization"
        element={<IssuanceSuccess isAuthorization />}
      ></Route>
      <Route
        path="view"
        element={<View authorizations={authorizations} setToast={setToast} />}
      ></Route>
    </Routes>
  );
}

export default function ProtectedContainer({
  setToast,
  session,
  setSession,
  authorizations,
  setOneAuthorization,
  fetchAuthorizations,
  relyingParties,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  let token = searchParams.get('token');

  useEffect(() => {
    if (session?.identityNP) {
      if (token) {
        searchParams.delete('token');
        setSearchParams(searchParams, { replace: true });
      }
      return;
    }
    if (token) {
      axios
        .get(`/api/session/${token}/result`)
        .then(({ data }) => {
          setSession(data);
          fetchAuthorizations();
          setToast({
            message: `Je bent ingelogd als ${data?.identityNP?.volledigeNaam}`,
            color: 'green',
          });
        })
        .catch((error) => {
          console.log(error.message);
          setToast({ message: 'Er ging iets mis.', color: '#cc2020' });
        })
        .finally(() => {
          searchParams.delete('token');
          setSearchParams(searchParams, { replace: true });
        });
    }
  }, [token]);

  if (session?.identityNP)
    return (
      <Protected
        session={session}
        setSession={setSession}
        authorizations={authorizations}
        setOneAuthorization={setOneAuthorization}
        setToast={setToast}
        relyingParties={relyingParties}
      />
    );
  if (token) return <div>loading session...</div>;
  return <IrmaDisclose />;
}
