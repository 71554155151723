import React from 'react';
import RelyingParties from './generic/RelyingParties';

export default function PickAuthorization({ relyingParties }) {
  return (
    <RelyingParties
      relyingParties={relyingParties}
      title={'Gemachtigd worden'}
      subTitle={'Waar wilt u zaken gaan regelen voor je organisatie?'}
    />
  );
}
